// @ts-expect-error TS(7023): 'classNames' implicitly has return type 'any' beca... Remove this comment to see the full error message
export const classNames = (...args: $TSFixMe[]) => {
    const hasOwn = {}.hasOwnProperty;
    const classes = [];

    for (let i = 0; i < args.length; i++) {
        const arg = args[i];
        if (!arg) continue;

        const argType = typeof arg;

        if (argType === 'string' || argType === 'number') {
            classes.push(arg);
        } else if (Array.isArray(arg)) {
            if (arg.length) {
                // @ts-expect-error TS(7022): 'inner' implicitly has type 'any' because it does ... Remove this comment to see the full error message
                // eslint-disable-next-line prefer-spread
                const inner = classNames.apply(null, arg);
                if (inner) {
                    classes.push(inner);
                }
            }
        } else if (argType === 'object') {
            if (arg.toString === Object.prototype.toString) {
                // eslint-disable-next-line no-restricted-syntax
                for (const key in arg) {
                    if (hasOwn.call(arg, key) && arg[key]) {
                        classes.push(key);
                    }
                }
            } else {
                classes.push(arg.toString());
            }
        }
    }

    return classes.join(' ');
};

export const roundNum = (num: $TSFixMe, decimalPlaces = 2) => {
    if (num) {
        const factor = 10 ** decimalPlaces;
        return Math.round((parseFloat(num) + Number.EPSILON) * factor) / factor;
    }
    return 0;
};

export const convertToFixedDecimal = (value: $TSFixMe) => {
    return roundNum(value, 2);
};
