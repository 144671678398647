export const METRIC_ATTRIBUTE_UNIT_ENUM = {
    M: 5,
    SQM: 6,
    CUM: 7,
    KG: 12,
    TONNE: 13,
    COUNT: 4,
    RATIO: 10,
    FREETEXT: 11,
    MM: 16,
    SQMM: 17
};
export const IMPERIAL_ATTRIBUTE_UNIT_ENUM = {
    FT: 1,
    SQFT: 2,
    CUYD: 3,
    COUNT: 4,
    LB: 8,
    TON: 9,
    RATIO: 10,
    FREETEXT: 11,
    INCH: 14,
    SQINCH: 15
};

export const METRIC_TO_IMPERIAL_UNIT = {
    [METRIC_ATTRIBUTE_UNIT_ENUM.M]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FT,
    [METRIC_ATTRIBUTE_UNIT_ENUM.SQM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQFT,
    [METRIC_ATTRIBUTE_UNIT_ENUM.CUM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.CUYD,
    [METRIC_ATTRIBUTE_UNIT_ENUM.KG]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.LB,
    [METRIC_ATTRIBUTE_UNIT_ENUM.TONNE]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.TON,
    [METRIC_ATTRIBUTE_UNIT_ENUM.COUNT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.COUNT,
    [METRIC_ATTRIBUTE_UNIT_ENUM.RATIO]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.RATIO,
    [METRIC_ATTRIBUTE_UNIT_ENUM.FREETEXT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FREETEXT,
    [METRIC_ATTRIBUTE_UNIT_ENUM.MM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.INCH,
    [METRIC_ATTRIBUTE_UNIT_ENUM.SQMM]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQINCH
};
export const IMPERIAL_TO_METRIC_UNIT = {
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.FT]: METRIC_ATTRIBUTE_UNIT_ENUM.M,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQFT]: METRIC_ATTRIBUTE_UNIT_ENUM.SQM,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.CUYD]: METRIC_ATTRIBUTE_UNIT_ENUM.CUM,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.LB]: METRIC_ATTRIBUTE_UNIT_ENUM.KG,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.TON]: METRIC_ATTRIBUTE_UNIT_ENUM.TONNE,
    [METRIC_ATTRIBUTE_UNIT_ENUM.COUNT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.COUNT,
    [METRIC_ATTRIBUTE_UNIT_ENUM.RATIO]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.RATIO,
    [METRIC_ATTRIBUTE_UNIT_ENUM.FREETEXT]: IMPERIAL_ATTRIBUTE_UNIT_ENUM.FREETEXT,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.INCH]: METRIC_ATTRIBUTE_UNIT_ENUM.MM,
    [IMPERIAL_ATTRIBUTE_UNIT_ENUM.SQINCH]: METRIC_ATTRIBUTE_UNIT_ENUM.SQMM
};
export const ATTRIBUTE_UNITS_ENUM = {
    ...METRIC_ATTRIBUTE_UNIT_ENUM,
    ...IMPERIAL_ATTRIBUTE_UNIT_ENUM
};
export const ATTRIBUTE_UNITS_VALUE = {
    [ATTRIBUTE_UNITS_ENUM.FT]: 'ft',
    [ATTRIBUTE_UNITS_ENUM.SQFT]: 'sqft',
    [ATTRIBUTE_UNITS_ENUM.CUYD]: 'cuyd',
    [ATTRIBUTE_UNITS_ENUM.COUNT]: 'count',
    [ATTRIBUTE_UNITS_ENUM.M]: 'm',
    [ATTRIBUTE_UNITS_ENUM.SQM]: 'sqm',
    [ATTRIBUTE_UNITS_ENUM.CUM]: 'cum',
    [ATTRIBUTE_UNITS_ENUM.LB]: 'lb',
    [ATTRIBUTE_UNITS_ENUM.TON]: 'ton',
    [ATTRIBUTE_UNITS_ENUM.RATIO]: 'ratio',
    [ATTRIBUTE_UNITS_ENUM.KG]: 'kg',
    [ATTRIBUTE_UNITS_ENUM.TONNE]: 't',
    [ATTRIBUTE_UNITS_ENUM.FREETEXT]: 'free text',
    [ATTRIBUTE_UNITS_ENUM.MM]: 'mm',
    [ATTRIBUTE_UNITS_ENUM.SQMM]: 'sq mm',
    [ATTRIBUTE_UNITS_ENUM.INCH]: 'inch',
    [ATTRIBUTE_UNITS_ENUM.SQINCH]: 'sq inch'
};

// assemblies units
export const METRIC_ASSEMBLY_UNIT_ENUM = {
    M: 5,
    SQM: 6,
    CUM: 7,
    COUNT: 4
};
export const IMPERIAL_ASSEMBLY_UNIT_ENUM = {
    FT: 1,
    SQFT: 2,
    CUYD: 3,
    COUNT: 4
};
export const ASSEMBLY_UNITS_ENUM = {
    ...METRIC_ASSEMBLY_UNIT_ENUM,
    ...IMPERIAL_ASSEMBLY_UNIT_ENUM
};
export const ASSEMBLY_UNITS_VALUE = {
    [ASSEMBLY_UNITS_ENUM.FT]: 'ft',
    [ASSEMBLY_UNITS_ENUM.SQFT]: 'sqft',
    [ASSEMBLY_UNITS_ENUM.CUYD]: 'cuyd',
    [ASSEMBLY_UNITS_ENUM.COUNT]: 'count',
    [ASSEMBLY_UNITS_ENUM.M]: 'm',
    [ASSEMBLY_UNITS_ENUM.SQM]: 'sqm',
    [ASSEMBLY_UNITS_ENUM.CUM]: 'cum'
};
export const IMPERIAL_ASSEMBLY_UNIT_NAMES = {
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.FT]: 'ft',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQFT]: 'ft2',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.CUYD]: 'yd3',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.COUNT]: 'unit'
};
export const METRIC_ASSEMBLY_UNIT_NAMES = {
    [METRIC_ASSEMBLY_UNIT_ENUM.M]: 'm',
    [METRIC_ASSEMBLY_UNIT_ENUM.SQM]: 'm2',
    // @ts-expect-error TS(2339): Property 'M3' does not exist on type '{ M: number;... Remove this comment to see the full error message
    [METRIC_ASSEMBLY_UNIT_ENUM.M3]: 'm3',
    [METRIC_ASSEMBLY_UNIT_ENUM.COUNT]: 'unit'
};
export const ASSEMBLY_UNIT_NAMES = {
    ...IMPERIAL_ASSEMBLY_UNIT_NAMES,
    ...METRIC_ASSEMBLY_UNIT_NAMES
};
export const IMPERIAL_ASSEMBLY_UNIT_NAMES_V2 = {
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.FT]: 'ft',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.SQFT]: 'ft2',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.CUYD]: 'yd3',
    [IMPERIAL_ASSEMBLY_UNIT_ENUM.COUNT]: 'count'
};
export const METRIC_ASSEMBLY_UNIT_NAMES_V2 = {
    [METRIC_ASSEMBLY_UNIT_ENUM.M]: 'm',
    [METRIC_ASSEMBLY_UNIT_ENUM.SQM]: 'm2',
    [METRIC_ASSEMBLY_UNIT_ENUM.CUM]: 'm3',
    [METRIC_ASSEMBLY_UNIT_ENUM.COUNT]: 'count'
};
export const ASSEMBLY_UNIT_NAMES_V2 = {
    ...IMPERIAL_ASSEMBLY_UNIT_NAMES_V2,
    ...METRIC_ASSEMBLY_UNIT_NAMES_V2
};

export const IMPERIAL_ASSEMBLY_UNIT_OPTIONS = [
    { name: 'ft', value: ASSEMBLY_UNITS_ENUM.FT },
    { name: 'ft2', value: ASSEMBLY_UNITS_ENUM.SQFT },
    { name: 'yd3', value: ASSEMBLY_UNITS_ENUM.CUYD },
    { name: 'count', value: ASSEMBLY_UNITS_ENUM.COUNT }
];

export const METRIC_ASSEMBLY_UNIT_OPTIONS = [
    { name: 'm', value: ASSEMBLY_UNITS_ENUM.M },
    { name: 'm2', value: ASSEMBLY_UNITS_ENUM.SQM },
    { name: 'm3', value: ASSEMBLY_UNITS_ENUM.CUM },
    { name: 'count', value: ASSEMBLY_UNITS_ENUM.COUNT }
];
