import { captureException } from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';

import message from 'antd/es/message';

import VectorLayer from 'ol/layer/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import Style from 'ol/style/Style';
import { Fill } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';

import { getGeom } from '@turf/invariant';
import lineIntersect from '@turf/line-intersect';
import { featureCollection, feature } from '@turf/helpers';
import buffer from '@turf/buffer';
import difference from '@turf/difference';
import booleanIntersects from '@turf/boolean-intersects';
import booleanContains from '@turf/boolean-contains';
import intersect from '@turf/intersect';
import area from '@turf/area';

import { HIGHLIGHT_STYLE, MODIFY_STYLE, ZONAL_STYLE } from '../MapBase';
import { GEOMETRY_TYPE_STRING, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { layerTracker, outputMap } from '../MapInit';
import { changeMapCursor } from '../../../Utils/HelperFunctions';
import { useRequest } from '../../../Stores/Request';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { getRandomHexColor } from '../../../Components/tags/helpers';

class ZoneMeasurement extends Observer {
    draw: $TSFixMe;

    invalidSpace: $TSFixMe;

    isDrawing: $TSFixMe;

    lastGeometry: $TSFixMe;

    layers: $TSFixMe;

    lineLayer: $TSFixMe;

    lotFeature: $TSFixMe;

    mapObj: $TSFixMe;

    modify: $TSFixMe;

    outsideParcel: $TSFixMe;

    polygonDrawCoordinate: $TSFixMe;

    polygonLayer: $TSFixMe;

    select: $TSFixMe;

    sequence: $TSFixMe;

    snap: $TSFixMe;

    tempLayers: $TSFixMe;

    zoneId: $TSFixMe;

    zoneLayers: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.zoneLayers = [];
        this.draw = null;
        this.lineLayer = null;
        this.polygonLayer = null;
        this.invalidSpace = false;
        this.layers = [];
        this.sequence = 1;
        this.modify = null;
        this.zoneId = null;
        this.select = null;
        this.tempLayers = [];
        this.lotFeature = null;
        this.polygonDrawCoordinate = null;
        this.isDrawing = false;
        this.snap = null;
    }

    on({ geometryType, newZone = null, zoneId = null }: $TSFixMe) {
        if (zoneId) {
            this.mapObj.map.on('pointermove', this.handlePointerMove);
            // update editZoneActive state true
            this.dispatch({ type: 'EDIT_ZONE_ACTIVE', payload: true });

            // zone edit logic
            this.zoneId = zoneId;
            let zoneLayer;
            this.loadZoneLayers({ zoneId });

            if (zoneId) {
                zoneLayer = this.mapObj.getLayerById(zoneId);
                zoneLayer.setStyle(HIGHLIGHT_STYLE);
            }

            this.select = new Select({
                // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; style: Style[]... Remove this comment to see the full error message
                wrapX: false,
                style: MODIFY_STYLE
            });
            // set modify style to layer in case of zone edit
            if (this.zoneId) {
                zoneLayer.setStyle(MODIFY_STYLE);
            }
            this.modify = new Modify({
                source: zoneLayer.getSource(),
                condition: () => !this.invalidSpace,
                snapToPointer: true
            });

            this.modify.on('modifystart', (e: $TSFixMe) => {
                const geom = e.features.getArray()[0]?.getGeometry();
                this.lastGeometry = geom.clone();
                const [lotFeature] = this.mapObj.getParcelFeaturesAtCoordinate(geom.getClosestPoint([1, 1]), true);
                this.lotFeature = lotFeature;
            });
            this.mapObj.map.addInteraction(this.modify);

            const snapSource = new VectorSource({ wrapX: false });
            this.addSnapInteraction({ snapSource });

            this.mapObj.map.on('contextmenu', this.removePointOnRightClick);
            this.modify.on('modifyend', this.handleChangedLayers);
        } else {
            // zone create tool logic
            if (!newZone) return;
            // if user is switching between line and polygon
            if (this.lineLayer || this.polygonLayer) this.switch();
            else this.off();

            this.mapObj.map.on('pointermove', this.handlePointerMove);
            this.loadZoneLayers({});

            /*
            Check for each parcel feature (lot boundary):
                If any existing zone feature intersects with the lot boundary, dont make a temp zone layer
                Else create a temp zone layer, ie, replica of that lot boundary
            */
            this.layers.forEach((layer: $TSFixMe) => {
                const isVisible = layer.getVisible();
                if (isVisible && layer.get('name') === MAP_LAYERS.PARCEL) {
                    const lotFeatures = layer.getSource()?.getFeatures();
                    lotFeatures.forEach((lotFeature: $TSFixMe) => {
                        let isZonePresent = false;
                        this.zoneLayers.forEach((zoneLayer: $TSFixMe) => {
                            // Each zone layer have one zone(feature) only
                            const zoneFeature = zoneLayer?.getSource()?.getFeatures()[0];
                            if (!zoneFeature) return null;
                            const zoneFeatureGeojson = outputMap.getGeojsonByFeature(zoneFeature);
                            const lotFeatureGeoJson = outputMap.getGeojsonByFeature(lotFeature);
                            if (booleanIntersects(lotFeatureGeoJson, zoneFeatureGeojson)) {
                                isZonePresent = true;
                                return null;
                            }
                            return null;
                        });
                        if (!isZonePresent) {
                            this.addTempLayer(lotFeature);
                        }
                    });
                    // dont check for layers other than "parcel layer"
                    return null;
                }
                return null;
            });

            // For drawing lines and polygons
            let sourceDrawnLines;
            let sourceDrawPolygons;
            if (geometryType === 'LineString' && !this.lineLayer) {
                changeMapCursor(this.invalidSpace, 'allowed');
                sourceDrawnLines = new VectorSource({ wrapX: false });
                this.lineLayer = new VectorLayer({
                    source: sourceDrawnLines
                });
                this.mapObj.map.addLayer(this.lineLayer);
            } else if (geometryType === 'Polygon' && !this.polygonLayer) {
                changeMapCursor(this.invalidSpace, 'not-allowed');

                sourceDrawPolygons = new VectorSource({ wrapX: false });

                this.polygonLayer = new VectorLayer({
                    source: new VectorSource({ wrapX: false })
                });
                this.mapObj.map.addLayer(this.polygonLayer);
            }
            // During switching between line/polygon Draw interaction is not removed
            if (!this.draw) {
                this.draw = new Draw({
                    source: geometryType === GEOMETRY_TYPE_STRING.POLYGON ? sourceDrawPolygons : sourceDrawnLines,
                    type: geometryType,
                    condition: e => {
                        const mouseClick = e.originalEvent.button;
                        const allowClick = this.polygonLayer !== null ? !this.invalidSpace : true;
                        if (mouseClick === 2 || mouseClick === 1 || !allowClick) {
                            return false;
                        }
                        return true;
                    },
                    snapTolerance: 1,
                    ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
                });
                this.mapObj.map.addInteraction(this.draw);
            }

            // Snapping when drawing zone polygons
            if (geometryType === GEOMETRY_TYPE_STRING.POLYGON) {
                this.addSnapInteraction({ snapSource: sourceDrawPolygons });
            }

            this.draw.on('drawstart', (e: $TSFixMe) => {
                this.isDrawing = true;
                this.polygonDrawCoordinate = e.feature?.getGeometry()?.getCoordinates()[0];
            });
            this.draw.on('drawend', this.drawEnd);

            document.addEventListener('keydown', this.removeLastPointOnBack);
        }
    }

    addSnapInteraction = ({ snapSource }: $TSFixMe) => {
        // Add all the zone features to snapSource to enable snapping for all..
        const zoneFeatures: $TSFixMe = [];

        this.layers.forEach((layer: $TSFixMe) => {
            if (layer?.get('name') === MAP_LAYERS.ZONE) {
                const features = layer.getSource()?.getFeatures();
                if (features.length > 0) {
                    zoneFeatures.push(features[0]);
                }
            }
        });
        snapSource.addFeatures(zoneFeatures);
        this.snap = new Snap({
            source: snapSource
        });
        this.mapObj.map.addInteraction(this.snap);
    };

    loadZoneLayers({ zoneId }: $TSFixMe) {
        // check if zone layers are already present
        this.layers = this.mapObj.map.getLayers()?.getArray();
        this.layers.forEach((layer: $TSFixMe) => {
            const isVisible = layer.getVisible();
            if (isVisible && layer?.get('name') === MAP_LAYERS.ZONE && !layer?.get('isTemp')) {
                this.zoneLayers.push(layer);
                if (!zoneId) this.sequence += 1; // increase seq as the new zone name will be added acc to that
            }
        });
    }

    handlePointerMove = (e: $TSFixMe) => {
        const outsideParcel = !this.mapObj.coordsExistsInParcel(e.coordinate);
        // Condition for pointermove when zone is being edited
        if (this.zoneId) {
            this.invalidSpace = outsideParcel;
            // Can't create a zone polygon interseting more than 1 zone...
        } else if (this.polygonDrawCoordinate) {
            this.mapObj.map.getLayers().forEach((layer: $TSFixMe) => {
                if (layer.get('name') === MAP_LAYERS.ZONE) {
                    // Zone feature at drawn-polygon coordinate..
                    const zonesAtDrawnGeom = layer?.getSource()?.getFeaturesAtCoordinate(this.polygonDrawCoordinate[0]);
                    if (zonesAtDrawnGeom && zonesAtDrawnGeom.length > 0) {
                        const currentZoneFeature = zonesAtDrawnGeom[0];
                        // mouse location to be made invalid if its outside parcel or intersects other zones
                        this.invalidSpace = this.outsideParcel
                            ? false
                            : this.isIntersectingOtherZones(e, currentZoneFeature);
                    }
                }
            });
        }
        changeMapCursor(this.invalidSpace, 'not-allowed');
    };

    // Function to check if the coordinates are intersecting any other zones..
    isIntersectingOtherZones = (e: $TSFixMe, currentZoneFeature: $TSFixMe) => {
        // Zone features other than the current zone
        const zoneFeatures = this.zoneLayers.reduce((filteredFeatures: $TSFixMe, zone: $TSFixMe) => {
            const feature = zone.getSource()?.getFeatures()[0];
            if (feature !== currentZoneFeature) {
                filteredFeatures.push(feature);
            }
            return filteredFeatures;
        }, []);

        if (this.zoneId) {
            // @ts-expect-error TS(2339): Property 'coordsExistsInZones' does not exist on t... Remove this comment to see the full error message
            return outputMap.coordsExistsInZones(e.coordinate, zoneFeatures, currentZoneFeature);
        } else {
            let isIntersecting = false;
            zoneFeatures?.forEach((zoneFeature: $TSFixMe) => {
                if (zoneFeature?.getGeometry()?.intersectsCoordinate(e.coordinate)) isIntersecting = true;
            });
            return isIntersecting;
        }
    };

    // Add temp layers not visible to users to divide zones (removed after creating zones and switching off tool)
    addTempLayer = (feature: $TSFixMe) => {
        const src = new VectorSource({
            features: [feature],
            wrapX: false
        });
        const newTempLayer = new VectorImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ name: string; isTemp: boolean;... Remove this comment to see the full error message
            name: MAP_LAYERS.ZONE,
            isTemp: true, // to check the layer is temp and remove when the zone is splitted
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 0, 0, 0)'
                })
            })
        });

        newTempLayer.setSource(src);

        this.tempLayers.push(newTempLayer);
        this.mapObj.map.addLayer(newTempLayer);
    };

    addZoneLayer = (feature: $TSFixMe) => {
        const src = new VectorSource({
            wrapX: false
        });
        src.addFeatures([feature]);

        // generate new id
        const layerId = uuidv4();

        // zone name acc to the sequence
        const layerName = `Zone ${this.sequence}`;
        this.sequence += 1;

        const data = {
            id: layerId,
            name: layerName,
            area: 0,
            style: {
                color: getRandomHexColor(),
                width: 3,
                opacity: 0.3,
                is_visible: true,
                hide_label: false
            }
        };
        const newZoneLayer = new VectorImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: any; style: Style; zIndex:... Remove this comment to see the full error message
            id: layerId,
            style: ZONAL_STYLE(
                layerName,
                data.style.color,
                data.style.opacity,
                data?.style?.hide_label,
                data.style.width
            ),
            zIndex: LAYER_INDEX.ZONE,
            zoneName: layerName,
            layerData: data,
            name: MAP_LAYERS.ZONE
        });

        newZoneLayer.setSource(src);
        this.zoneLayers.push(newZoneLayer);
        this.mapObj.map.addLayer(newZoneLayer);

        layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
    };

    removeLastPointOnBack = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (this.isDrawing && (event.ctrlKey || event.metaKey) && KeyID === 90) {
            event.stopImmediatePropagation();
            this.draw.removeLastPoint();
        }
        if (KeyID === 27) {
            this.draw.abortDrawing();
            // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
            this.drawEnd();
        }
    };

    drawEnd = (e: $TSFixMe) => {
        this.polygonDrawCoordinate = null;
        this.isDrawing = false;
        const FormatGeoJSON = new GeoJSON();
        const drawnGeoJSON = FormatGeoJSON.writeFeatureObject(e.feature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
        const drawnGeometry = getGeom(drawnGeoJSON);
        // if drawing started from double click, split is not working
        // so removing the first two coordinates if equal
        if (
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            drawnGeometry?.coordinates?.length > 2 &&
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            JSON.stringify(drawnGeometry.coordinates[0]) === JSON.stringify(drawnGeometry.coordinates[1])
        ) {
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            drawnGeometry.coordinates.splice(1, 1);
        }
        // If drawn geometry is polygon, check if its intersecting with more than one zone or not
        if (drawnGeometry.type === 'LineString' || drawnGeometry.type === 'Polygon') {
            if (drawnGeometry.type === 'Polygon') {
                if (area(drawnGeometry) === 0) {
                    message.error('Oops! Please drawn a valid polygon.');
                    return;
                }

                const { zonesContained, inLot } = this.noOfzonesIntersecting(drawnGeometry);

                let removeFeature = false;
                // If no zone area exist in lot boundary, remove it.
                if (!inLot) {
                    removeFeature = true;
                    message.error('Oops! Zone drawn is outside the lot boundary.');
                } else if (zonesContained === 0) {
                    // When the user tries to draw polygon in the area with no zones
                    removeFeature = true;
                    this.addZoneLayer(e.feature);
                }
                if (removeFeature) {
                    const src = this.polygonLayer.getSource();
                    src.on('addfeature', (e: $TSFixMe) => {
                        src.removeFeature(e.feature);
                    });
                    this.polygonLayer.setSource(src);

                    this.notifyObservers(TOOL_EVENT.ZONE_DIVISION_TOOL);
                    return;
                }
            }
            let addedDrawnZone = false;
            const tempLayersToBeRemoved: $TSFixMe = [];
            this.layers.forEach((layer: $TSFixMe) => {
                // check only for zoneLayers
                if (layer.get('name') === MAP_LAYERS.ZONE) {
                    const layerSource = layer.getSource();
                    layerSource.forEachFeature((feature: $TSFixMe) => {
                        const featureGeo = FormatGeoJSON.writeFeatureObject(feature, {
                            dataProjection: 'EPSG:4326',
                            featureProjection: 'EPSG:3857'
                        });
                        // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
                        const featureGemoetry = getGeom(featureGeo);
                        try {
                            let splittedFeatures;
                            if (featureGemoetry.type === GEOMETRY_TYPE_STRING.POLYGON) {
                                // Calculate intersection only when drawing zone polygon
                                const intersection =
                                    drawnGeometry.type !== 'LineString'
                                        ? // @ts-expect-error TS(2345): Argument of type 'Geometries | GeometryCollection'... Remove this comment to see the full error message
                                          intersect(featureGemoetry, drawnGeometry)
                                        : null;
                                // split only if the drawn geometry is a LineString or if the feature geometry completely contains the drawn geometry
                                if (drawnGeometry.type === 'LineString' || (intersection && area(intersection) > 0)) {
                                    splittedFeatures = this.polygonCut(featureGemoetry, drawnGeometry);
                                }
                            }
                            if (splittedFeatures !== null) {
                                const features = FormatGeoJSON.readFeatures(splittedFeatures, {
                                    dataProjection: 'EPSG:4326',
                                    featureProjection: 'EPSG:3857'
                                });
                                // Splitted features will always be 2 in count in case of zone polygon
                                const feature1 = features[0];
                                const feature2 = features[1];
                                let featureArr = [feature1];
                                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                                if (feature1.getGeometry().getType() === 'MultiPolygon') {
                                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                                    const coordsArr = feature1.getGeometry().getCoordinates();
                                    featureArr = [];

                                    coordsArr.forEach((coords: $TSFixMe) => {
                                        const poly = new Polygon(coords);
                                        const feat = new Feature(poly);
                                        featureArr.push(feat);
                                    });
                                }
                                // If line is diving a zone in more than 2 parts
                                if (features?.length > 2) {
                                    featureArr.push(...features.slice(2));
                                }
                                // remove prev feature and add new splitted features(1 -> new zone layer 2 -> in existing layer)
                                layerSource.removeFeature(feature);
                                // Add the drawn geometry only once when looping through zone layers
                                if (!addedDrawnZone) this.addZoneLayer(feature2);
                                layerSource.addFeatures(featureArr);

                                // if splitting temp layer, then remove it and add a new zone layer
                                if (layer.get('isTemp') === true) {
                                    tempLayersToBeRemoved.push(layer);
                                    featureArr.forEach(feat => this.addZoneLayer(feat));
                                } else {
                                    // Push layer in tracker
                                    const layerId = feature.get('layerId');
                                    layerId && layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
                                }
                                addedDrawnZone = true;
                            }
                        } catch (err) {
                            captureException(err);
                        }
                    });
                }
            });
            // @ts-expect-error TS(7006): Parameter 'layer' implicitly has an 'any' type.
            tempLayersToBeRemoved.forEach(layer => this.mapObj.map.removeLayer(layer));
        }
        this.mapObj.map.removeLayer(this.lineLayer);
        this.mapObj.map.removeLayer(this.polygonLayer);

        this.notifyObservers(TOOL_EVENT.ZONE_DIVISION_TOOL);
    };

    // Function to check the number of zones contained and intersected by the drawn zone
    noOfzonesIntersecting(drawnGeometry: $TSFixMe) {
        // zonesContained -> number of zones containing drawnGeometry completely
        let zonesContained = 0;
        let inLot = false;

        const projection = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        };
        const FormatGeoJSON = new GeoJSON();
        this.layers.forEach((layer: $TSFixMe) => {
            if (layer.get('name') === MAP_LAYERS.ZONE) {
                const layerSource = layer.getSource();
                layerSource.forEachFeature((feature: $TSFixMe) => {
                    const featureGeo = FormatGeoJSON.writeFeatureObject(feature, projection);
                    // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
                    const featureGemoetry = getGeom(featureGeo);
                    // @ts-expect-error TS(2345): Argument of type 'Geometries | GeometryCollection'... Remove this comment to see the full error message
                    const intersection = intersect(featureGemoetry, drawnGeometry);

                    // zonesContained should be increased while snapping also (zone contained completely but sharing a boundary)
                    // @ts-expect-error TS(2345): Argument of type 'Geometries | GeometryCollection'... Remove this comment to see the full error message
                    if (booleanContains(featureGemoetry, drawnGeometry) || (intersection && area(intersection) > 1)) {
                        zonesContained += 1;
                    }
                });
            } else if (layer.get('name') === MAP_LAYERS.PARCEL) {
                const layerSource = layer.getSource();

                layerSource.forEachFeature((feature: $TSFixMe) => {
                    const featureGeo = FormatGeoJSON.writeFeatureObject(feature, projection);
                    // @ts-expect-error TS(2345): Argument of type 'Feature<Geometry, GeoJsonPropert... Remove this comment to see the full error message
                    const featureGemoetry = getGeom(featureGeo);
                    // @ts-expect-error TS(2345): Argument of type 'Geometries | GeometryCollection'... Remove this comment to see the full error message
                    if (booleanIntersects(featureGemoetry, drawnGeometry)) {
                        inLot = true;
                    }
                });
            }
        });
        return { zonesContained, inLot };
    }

    polygonCut(polygon: $TSFixMe, drawnGeometry: $TSFixMe) {
        if (polygon.type !== 'Polygon' && polygon.type !== 'MultiPolygon') {
            return null;
        }
        if (drawnGeometry.type === 'LineString') {
            const intersectPoints = lineIntersect(polygon, drawnGeometry);
            if (intersectPoints.features.length < 2) {
                return null;
            }
        }
        // linePolygon represents an extremely narrow polygon made using the line
        const linePolygon =
            drawnGeometry.type === 'LineString'
                ? buffer(drawnGeometry, 0.00000001, { units: 'kilometers' })
                : drawnGeometry;
        const splittedPolygon = difference(polygon, linePolygon);
        // if polygon is splitted by a line
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        if (splittedPolygon.geometry.type === 'MultiPolygon' && drawnGeometry.type === 'LineString') {
            // @ts-expect-error TS(2339): Property 'coordinates' does not exist on type 'Geo... Remove this comment to see the full error message
            const { coordinates } = getGeom(splittedPolygon);
            const cutFeatures = coordinates.map((coord: $TSFixMe) => feature({ type: 'Polygon', coordinates: coord }));
            return featureCollection(cutFeatures);
        } else if (drawnGeometry.type === 'Polygon') {
            // if splitted by a polygon
            const cutFeatures = [splittedPolygon];
            const drawnPolygon = feature(drawnGeometry);
            cutFeatures.push(drawnPolygon);
            // @ts-expect-error TS(2345): Argument of type '(Feature<Polygon | MultiPolygon,... Remove this comment to see the full error message
            return featureCollection(cutFeatures);
        }
        return null;
    }

    handleChangedLayers = (e: $TSFixMe) => {
        const features = e.features.getArray();
        const feature = features.length && features[0];
        if (feature) {
            if (this.invalidSpace) {
                feature.setGeometry(this.lastGeometry.clone());
                return;
            }

            this.lastGeometry = feature.getGeometry().clone();
            const layerId = this.zoneId ? this.zoneId : feature.get('layerId');
            // Push layer in tracker
            layerTracker.push(this.mapObj.getLayerName(layerId), layerId);

            this.notifyObservers(TOOL_EVENT.ZONE_DIVISION_TOOL, { isEdit: true });
        }
        changeMapCursor(this.invalidSpace, 'allowed');
    };

    removePointOnRightClick = (e: $TSFixMe) => {
        e.preventDefault();
        return this.modify.removePoint();
    };

    dispatch(action: $TSFixMe) {
        useRequest.getState()?.dispatch(action);
    }

    switch() {
        this.invalidSpace = false;
        if (this.draw) {
            this.mapObj.map.removeInteraction(this.draw);
        }
        this.draw = null;
        this.lineLayer = null;
        this.polygonLayer = null;
        this.modify = null;
        this.sequence = 1;
        // remove all temp layers
        this.tempLayers.forEach((layer: $TSFixMe) => this.mapObj.map.removeLayer(layer));
        this.tempLayers = [];
        this.polygonDrawCoordinate = null;
        this.isDrawing = false;
        this.snap && this.mapObj.map.removeInteraction(this.snap);
        this.snap = null;
    }

    off() {
        // these layers are not updated in store and hence need to be removed here only
        this.zoneLayers.forEach((layer: $TSFixMe) => this.mapObj.map.removeLayer(layer));
        this.zoneLayers = [];
        document.removeEventListener('keydown', this.removeLastPointOnBack);
        this.modify && this.mapObj.map.removeInteraction(this.modify);
        this.modify = null;
        this.switch();

        this.zoneId = null;
        this.lotFeature = null;
        this.mapObj.map.un('contextmenu', this.removePointOnRightClick);
        this.mapObj.map.un('pointermove', this.handlePointerMove);

        if (this.select) {
            this.select.getFeatures()?.clear();
            this.mapObj.map.removeInteraction(this.select);
        }
        this.select = null;

        this.dispatch({ type: 'EDIT_ZONE_ACTIVE', payload: false });
    }
}

export default ZoneMeasurement;
