import create from 'zustand';
import { devtools } from 'zustand/middleware';

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
    switch (type) {
        case 'COMPANY_CONFIG':
            return { ...state, comp_config: payload };
        default:
            return state;
    }
};

const store = (set: $TSFixMe) => ({
    comp_config: {},
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useCompanyConfig = create(devtools(store));
