import { useEffect, useCallback } from 'react';
import { DEFAULT_TITLE } from '../Constants/PageTitle';

export const useTitle = (title: string) => {
    useEffect(() => {
        document.title = title || DEFAULT_TITLE;
        return () => {
            document.title = DEFAULT_TITLE;
        };
    }, []);

    const setTitle = useCallback(_title => {
        document.title = _title;
    }, []);

    return { setTitle };
};
