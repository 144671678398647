import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { constructTagsByScope, constructTagsMap, massageTags } from '../Components/tags/helpers';
import { NETWORK_CALL_STATUS } from '../Constants/Constant';

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
    switch (type) {
        case 'SET_TAGS': {
            const tagsMap = constructTagsMap(payload);
            const tagsByScope = constructTagsByScope(payload);
            const massagedTags = massageTags(payload);
            return { tags: payload, tagsMap, tagsByScope, massagedTags };
        }
        case 'SET_FETCHING_STATUS': {
            return { fetchingStatus: payload };
        }
        default:
            return state;
    }
};

const store = (set: $TSFixMe) => ({
    tags: [],
    tagsMap: {},
    tagsByScope: {},
    massagedTags: {},
    fetchingStatus: NETWORK_CALL_STATUS.IDLE,
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useTags = create(devtools(store));
