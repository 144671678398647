import axios from 'axios';
import { captureException } from '@sentry/react';

import message from 'antd/es/message';

import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import VectorImageLayer from 'ol/layer/VectorImage';
import Point from 'ol/geom/Point';
import { getCenter } from 'ol/extent';
import union from '@turf/union';
import difference from '@turf/difference';
import booleanIntersects from '@turf/boolean-intersects';
import { featureCollection } from '@turf/helpers';
import buffer from '@turf/buffer';

import { LAYER_INDEX } from '../../../Constants/Constant';
import { hasMultiFeatures } from '../../../Utils/olutils';
import { getRequestData } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { interpolate, postAPI } from '../../../Utils/ApiCalls';
import { NEARBY_PARCELS } from '../../../Constants/Urls';
import { editParcel } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

class Node {
    added: $TSFixMe;

    childrens: $TSFixMe;

    geojson: $TSFixMe;

    id: $TSFixMe;

    constructor(geojson: $TSFixMe, id: $TSFixMe, added = false) {
        this.childrens = [];
        this.geojson = geojson;
        this.id = id;
        this.added = added;
    }

    /**
     * Add node in the current node
     * @param {*} node
     */
    add(node: $TSFixMe) {
        this.childrens.push(node);
    }

    /**
     * Remove all childrens of the current node
     */
    clear() {
        this.childrens.length = 0;
        this.added = false;
    }

    /**
     * Remove immidiate children by ID
     * @param {int} id
     */
    removeChildById(id: $TSFixMe) {
        const index = this.childrens.findIndex((n: $TSFixMe) => n.id === id);
        if (index > -1) {
            this.childrens.splice(index, 1);
        }
    }

    /**
     * Determine if current node is added into the parcel
     * @param {*} value
     */
    setAdded(value: $TSFixMe) {
        this.added = value;
    }

    clone = () => {
        function cloneNode(/** @type {Node} */ node: $TSFixMe, /** @type {Node} */ cloned: $TSFixMe) {
            for (let i = 0; i < node.childrens.length; i++) {
                cloned.childrens[i] = cloneNode(
                    node.childrens[i],
                    new Node(node.childrens[i].geojson, node.childrens[i].id, node.childrens[i].added)
                );
            }
            return cloned;
        }
        return cloneNode(this, new Node(this.geojson, this.id, this.added));
    };
}

class NearbyParcels extends Observer {
    axiosSource: $TSFixMe;

    initialParcelId: $TSFixMe;

    isApiCallDone: $TSFixMe;

    layer: $TSFixMe;

    mapObj: $TSFixMe;

    parcelSet: $TSFixMe;

    parcelStyle: $TSFixMe;

    requestId: $TSFixMe;

    root: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.layer = null;
        this.root = null;
        this.parcelSet = null;
        this.requestId = null;
        this.axiosSource = null;
        this.isApiCallDone = true;
        this.parcelStyle = null;
    }

    on({ requestId }: $TSFixMe) {
        const requestData = getRequestData();
        // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
        if (requestData.input?.is_edited) throw new Error('NO_SUPPORT_EDITED_PARCEL');

        // Backend/Frontend do not support multi polygon
        if (hasMultiFeatures(this.mapObj.getParcelLayer(true))) throw new Error('NO_SUPPORT_MULTI_LOT');

        this.parcelSet = new Set();
        this.requestId = requestId;
        this.axiosSource = axios.CancelToken.source();
        const parcelGeojson = editParcel.getParcelGeojson();
        this.root = new Node(parcelGeojson, Date.now());

        this.addPointerToOriginalParcel();

        this.layer = new VectorImageLayer({
            source: new VectorSource({ wrapX: false }),
            style: styleFn,
            zIndex: LAYER_INDEX.NEARBY_PARCEL
        });
        this.mapObj.addLayer(this.layer);

        this.fetchMore(this.root);

        this.mapObj.map.on('singleclick', this.onFeatureClick);
    }

    loadState() {
        const _this = this;
        return (stateParam: $TSFixMe) => {
            try {
                const state = stateParam[0];
                if (state.root instanceof Node) {
                    _this.root = state.root;
                    _this.parcelSet = state.parcelSet;
                    _this.renderParcelsFromRootNode();
                } else {
                    const id = _this.requestId;
                    _this.off();
                    _this.on({ requestId: id });
                }
            } catch (error) {
                captureException(error);
            }
        };
    }

    /**
     * Add pointer in the center of the original parcel
     */
    addPointerToOriginalParcel() {
        const parcelLayer = this.mapObj.getParcelLayer(true);
        this.parcelStyle = parcelLayer.getStyle();
        const style = new Style({
            image: new Icon({
                src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/pin.png',
                scale: 0.6,
                crossOrigin: 'anonymous'
            }),
            geometry: feature => {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                const extent = feature.getGeometry().getExtent();
                const center = getCenter(extent);
                return new Point(center);
            }
        });
        parcelLayer.setStyle([this.parcelStyle, style]);
    }

    /**
     * Fetch adjacent parcels from a node and maintain a Set
     * @param {Node} node
     */
    fetchMore(node: $TSFixMe) {
        const msg = message.loading('Loading Properties...', 0);
        const url = interpolate(NEARBY_PARCELS, [this.requestId]);
        const data = { geojson: node.geojson };
        postAPI(url, { data, cancelToken: this.axiosSource.token })
            .then(nearbyParcels => {
                msg();
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                nearbyParcels.forEach((parcel: $TSFixMe) => {
                    if (!this.parcelSet.has(parcel.id) && parcel.id !== this.initialParcelId) {
                        this.parcelSet.add(parcel.id);
                        node.add(new Node(parcel.geojson, parcel.id));
                    }
                });

                this.renderParcelsFromRootNode();
            })
            .catch(err => {
                captureException(err);
                msg();
            })
            .finally(() => {
                this.isApiCallDone = true;
            });
    }

    onFeatureClick = (e: $TSFixMe) => {
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (feature: $TSFixMe) => {
            this.addOrRemoveFeature(feature);
        });
    };

    /**
     * Get the Node object from the id
     * @param {int} id
     */
    getNodeById(id: $TSFixMe) {
        // @ts-expect-error TS(7023): 'find' implicitly has return type 'any' because it... Remove this comment to see the full error message
        function find(node: $TSFixMe) {
            if (node.id === id) {
                return node;
            }

            for (let i = 0; i < node.childrens.length; i++) {
                const child = node.childrens[i];
                // @ts-expect-error TS(7022): 'res' implicitly has type 'any' because it does no... Remove this comment to see the full error message
                const res = find(child);
                if (res) {
                    return res;
                }
            }
            return null;
        }
        return find(this.root);
    }

    /**
     * Decide whether to remove or add feature
     * @param {Feature} feature
     */
    addOrRemoveFeature(feature: $TSFixMe) {
        if (!this.isApiCallDone) return;

        const hasAdded = feature.get('hasAdded');
        const nodeId = feature.get('nodeId');
        const node = this.getNodeById(nodeId);
        if (!node) return;
        if (hasAdded) {
            /*
             * User clicks on remove icon
             * Find if parcel can be removed
             * Mark node as added = false (node is not a part of the parcel)
             * Clear all the non-touching childrens of the node
             * Remove all childrens from the Set
             */
            const canBeRemoved = canNodeBeRemoved(this.root, node);
            if (canBeRemoved) {
                node.setAdded(false);
                this.removeNonTouchingNonAddedParcels(node);

                this.renderParcelsFromRootNode();
                this.notifyObservers(TOOL_EVENT.NEARBY_PARCEL);
            } else {
                message.error('This parcel cannot be removed.');
            }
        } else if (this.isApiCallDone) {
            /*
             * User clicks on add icon
             * Mark node as added = true (node is a part of the parcel)
             * Fetch adjacent parcels
             */
            this.isApiCallDone = false;
            node.setAdded(true);
            this.fetchMore(node);
            this.notifyObservers(TOOL_EVENT.NEARBY_PARCEL);
        }
    }

    /**
     * Remove all non-touching and non-added child parcels from the target node
     * @param {Node} node
     */
    removeNonTouchingNonAddedParcels(node: $TSFixMe) {
        const mergedParcels = this.getFinalParcel();
        const _this = this;
        function find(node: $TSFixMe) {
            const len = node.childrens.length;
            const idsTobeRemoved = [];
            for (let i = 0; i < len; i++) {
                const currentNode = node.childrens[i];
                const intersection = booleanIntersects(mergedParcels.features[0], currentNode.geojson.features[0]);
                if (intersection) {
                    // node.add(currentNode);
                } else {
                    idsTobeRemoved.push(currentNode.id);
                }
                if (currentNode.childrens.length) {
                    find(currentNode);
                }
            }
            idsTobeRemoved.forEach(id => {
                _this.parcelSet.delete(id);
                node.removeChildById(id);
            });
        }
        find(node);
    }

    /**
     * Render all the parcels staring from the root node using recursive approach
     */
    renderParcelsFromRootNode() {
        const features: $TSFixMe = [];
        const format = new GeoJSON();

        function render(node: $TSFixMe) {
            for (let i = 0; i < node.childrens.length; i++) {
                const currentNode = node.childrens[i];
                const geom = format.readFeatures(currentNode.geojson, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                geom[0].set('nodeId', currentNode.id, true);
                geom[0].set('hasAdded', currentNode.added, true);
                features.push(geom[0]);

                if (currentNode.childrens.length) {
                    render(currentNode);
                }
            }
        }
        render(this.root);

        this.layer.getSource().clear();
        this.layer.getSource().addFeatures(features);
    }

    /**
     * Get the merged parcel
     */
    getFinalParcel() {
        const merged = mergeParcels(this.root);
        const fc = featureCollection([merged]);
        return fc;
    }

    off() {
        if (this.parcelStyle) {
            this.mapObj.getParcelLayer(true)?.setStyle(this.parcelStyle);
        }
        this.root = null;
        this.parcelSet = null;
        message.destroy();
        this.axiosSource && this.axiosSource.cancel();
        this.mapObj.map.un('singleclick', this.onFeatureClick);
        this.layer && this.mapObj.removeLayer(this.layer);
    }
}

export default NearbyParcels;

/**
 * Get feature style and +/- icon
 * @param {Feature} feature
 */
function styleFn(feature: $TSFixMe) {
    const imgSrc = feature.get('hasAdded')
        ? 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/output/minus.svg'
        : 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/output/plus.svg';

    const featureStyle = feature.get('hasAdded')
        ? new Style({
              stroke: new Stroke({
                  width: 3,
                  color: '#4361ee'
              })
          })
        : new Style({
              stroke: new Stroke({
                  width: 3,
                  color: 'red',
                  lineDash: [4, 8],
                  lineDashOffset: 6
              }),
              fill: new Fill({
                  color: 'rgba(255,0,0,0.12)'
              })
          });

    return [
        featureStyle,
        new Style({
            image: new Icon({
                src: imgSrc,
                scale: 0.6,
                crossOrigin: 'anonymous'
            }),
            geometry(feature) {
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                const extent = feature.getGeometry().getExtent();
                const center = getCenter(extent);
                return new Point(center);
            }
        })
    ];
}

/**
 * Merge all the added parcels from the root node
 * @param {Node} root
 */
function mergeParcels(root: $TSFixMe) {
    let merged = root.geojson.features[0];
    // @ts-expect-error TS(7023): 'find' implicitly has return type 'any' because it... Remove this comment to see the full error message
    function find(node: $TSFixMe) {
        for (let i = 0; i < node?.childrens?.length; i++) {
            const child = node.childrens[i];
            if (child.added) {
                const buffered = buffer(child.geojson.features[0], 0.1, { units: 'meters' });
                // @ts-expect-error TS(2345): Argument of type 'Feature<Polygon, Properties>' is... Remove this comment to see the full error message
                merged = union(merged, buffered);
            }
            // @ts-expect-error TS(7022): 'res' implicitly has type 'any' because it does no... Remove this comment to see the full error message
            const res = find(child);
            if (res) {
                return res;
            }
        }
        return null;
    }
    find(root);
    return merged;
}

/**
 * To identify whether the node can be removed or not
 * If there are more than 1 feature after removing the node then it can not be removed
 * @param {Node} root
 * @param {Node} node
 */
function canNodeBeRemoved(root: $TSFixMe, node: $TSFixMe) {
    const merged = mergeParcels(root);
    const diff = difference(merged, node.geojson.features[0]);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    const diffGeom = diff.geometry || {};
    if (diffGeom.type === 'MultiPolygon' && diffGeom.coordinates?.length > 1) {
        return false;
    } else {
        return true;
    }
}
