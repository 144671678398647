import message from 'antd/es/message';
import { MAP_LAYERS } from '../Constants/Constant';
import { layerTracker, mapObj, toolController } from '../MainPage/OlMap/MapInit';
import { TOOL_EVENT } from '../MainPage/Output/Toolbar/ToolController';
import { useContextMenu } from '../Stores/ContextMenu';
import { useRequest } from '../Stores/Request';
import { getClipboardPayload } from './olutils';

// helpers

const dispatch = ({ store, type, payload }: $TSFixMe) => {
    store.getState()?.dispatch({ type, payload });
};

const scroll = (layerId: $TSFixMe) => {
    document.getElementById(layerId)?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
    });
};

const mergeFeats = (selectedFeatures: $TSFixMe, feature: $TSFixMe) => {
    const isAlreadyPresent = selectedFeatures.find(
        (feat: $TSFixMe) => feat.get('layerId') === feature?.get('layerId') && feat?.get('id') === feature?.get('id')
    );
    if (isAlreadyPresent) return selectedFeatures;

    return [...selectedFeatures, feature];
};

// eslint-disable-next-line consistent-return
const handleLocateMe = (feature: $TSFixMe): any => {
    const currentPageId = useRequest?.getState()?.currentPageId;

    if (feature) {
        const layerId = feature?.get('layerId');
        const layer = mapObj?.getLayerById(layerId);
        const feature_id = layer?.get('layerData')?.feature?.feature_id;
        if (!feature_id) {
            return message.error('feature not found');
        }

        const measurementPanelCollapsed = useRequest?.getState()?.collapsed?.measurementPanel;
        if (measurementPanelCollapsed) {
            dispatch({ store: useRequest, type: 'SET_COLLAPSED', payload: { measurementPanel: false } });
        }

        scroll(feature_id);

        dispatch({
            store: useRequest,
            type: 'SET_FEATURE_OPEN_STATE',
            payload: {
                sheet_id: currentPageId,
                feature_id
            }
        });
        onClose();
    }
};

const handleClipboard = (feature: $TSFixMe, toolId: $TSFixMe) => {
    const selectedFeatures = useRequest.getState()?.selectedFeatures || [];
    const mergedFeats = mergeFeats(selectedFeatures, feature);

    const payload = getClipboardPayload({ features: mergedFeats, toolId });
    dispatch({ store: useRequest, type: 'SET_CLIPBOARD_FEATURES', payload });
    onClose();
};

const handleCallout = () => {
    dispatch({ store: useContextMenu, type: 'SET_CONTEXTMENU', payload: { visible: false } });
    dispatch({ store: useContextMenu, type: 'SET_CALLOUT_POPUP_VISIBILITY', payload: true });
};

const handlePaste = ({ toolId, shortcutKey }: $TSFixMe) => {
    toolController.handleToolClick({ id: toolId, shortcutKey });
    onClose();
};

const handleFlipRotate = (feature: $TSFixMe, toolId: $TSFixMe) => {
    const selectedFeatures = useRequest.getState()?.selectedFeatures || [];
    const mergedFeats = mergeFeats(selectedFeatures, feature);

    dispatch({ store: useRequest, type: 'SET_SELECTED_FEATURES', payload: mergedFeats });

    toolController.handleToolClick({ id: toolId });
    onClose();
    // after operation we're resetting our selected features to its original array
    dispatch({ store: useRequest, type: 'SET_SELECTED_FEATURES', payload: selectedFeatures });
};

const handleDelete = (feature: $TSFixMe) => {
    const selectedFeatures = useRequest.getState()?.selectedFeatures || [];
    const mergedFeats = mergeFeats(selectedFeatures, feature);

    mergedFeats.forEach((feature: $TSFixMe) => {
        let layerId = null;
        if (feature?.get('isLabel')) {
            layerId = MAP_LAYERS.LABELS;
        } else {
            layerId = feature.get('layerId');
        }
        const layer = mapObj.getLayerById(layerId);
        if (layer) {
            layer.getSource().removeFeature(feature);
            // Push layer in tracker
            layerTracker.push(layer.get('name'), feature.get('layerId'));
        }
    });

    layerTracker.getArray().length &&
        toolController.dispatchEvent(
            new CustomEvent('tool-event', {
                detail: { type: TOOL_EVENT.DELETE_FEATURE }
            })
        );
    setTimeout(onClose, 0);
};

const onClose = () => {
    dispatch({ store: useContextMenu, type: 'RESET_CONTEXTMENU' });
};

export { handleLocateMe, handleClipboard, handlePaste, handleDelete, onClose, handleCallout, handleFlipRotate };
