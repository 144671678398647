import React from 'react';
import loadersStyle from '../Styles/publicRoutes/loaders.module.less';

export const SharedViewLoader = (props: $TSFixMe) => {
    const { initialLoader, ldsRoller, ldsSm } = loadersStyle;
    let cls = props?.init ? `${initialLoader} ${ldsRoller}` : ldsRoller;
    cls += props?.size === 'sm' ? ldsSm : '';
    cls += props?.className ? ` ${props?.className}` : '';

    return (
        <div className={cls}>
            {Array.from({ length: 8 }, (_, index) => (
                <div key={index} />
            ))}
        </div>
    );
};
