import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import chunkReloadHandler from './Utils/ChunkReloadHandler';

import 'antd/dist/antd.less';
import './Styles/global/style.less';
import './Styles/global/tailwind.less';
import './Styles/global/ant.less';
import './Styles/mobile.less';
import './Styles/features.less';

import App from './App';
import { storage } from './Stores/AppStore';
import ErrorBoundary from './Components/ErrorBoundary';

// Error event listener
window.onerror = function onerror(message) {
    // Handle chunk load error
    // @ts-expect-error TS(2339): Property 'includes' does not exist on type 'string... Remove this comment to see the full error message
    if (message.includes('ChunkLoadError')) {
        chunkReloadHandler();
    }
};

// Initialize sentry (on prod only)

if (process.env.APP_ENV === 'prod') {
    Sentry.init({
        dsn: process.env.SENTRY_KEY,
        integrations: [new Integrations.BrowserTracing()],
        environment: 'production',
        tracesSampleRate: 1,
        release: process.env.SENTRY_RELEASE,
        ignoreErrors: ['TypeError: Failed to fetch']
    });
    const email = storage.get('emailId');
    if (email) {
        Sentry.configureScope(scope => {
            // Set user if email is available
            scope.setUser({ user_email: email });
        });
    }
}

ReactDOM.render(
    <Router>
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
            <App />
        </Sentry.ErrorBoundary>
    </Router>,
    document.getElementById('root')
);

// @ts-expect-error TS(1343): The 'import.meta' meta-property is only allowed wh... Remove this comment to see the full error message
if (import.meta.hot) {
    // @ts-expect-error TS(1343): The 'import.meta' meta-property is only allowed wh... Remove this comment to see the full error message
    import.meta.hot.accept();
}
