import create from 'zustand';

const initialState = { outputData: {}, fetchingOutput: false };

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
    let output_id: $TSFixMe;
    let outputs;
    let filteredOutput;
    switch (type) {
        case 'SET_OUTPUT':
            return {
                ...state,
                outputData: { ...payload }
            };
        case 'FETCHING_OUTPUT':
            return {
                ...state,
                fetchingOutput: payload
            };
        case 'SET_OUTPUT_EXTRAS':
            return {
                ...state,
                outputData: {
                    ...state.outputData,
                    [payload.key]: payload.value
                }
            };
        case 'UPDATE_FEATURE': {
            // payload => {feature_id, featureDetails: {description}}
            const { featureId, featureDetails } = payload || {};
            const outputData = state.outputData?.outputs || [];
            const filterOutput = outputData.find((output: $TSFixMe) => output.id === featureId);

            if (filterOutput) {
                filterOutput.feature = {
                    ...filterOutput.feature,
                    ...featureDetails
                };
                filterOutput.attributes = payload?.attributes || [];
                filterOutput.attribute_based_measurements = payload?.attribute_based_measurements || [];
                const newOutput = [...outputData];
                return {
                    ...state,
                    outputData: {
                        ...state.outputData,
                        outputs: newOutput
                    }
                };
            }
            return {
                ...state
            };
        }
        case 'SET_WIDTH':
            return {
                ...state,
                outputData: {
                    ...state.outputData,
                    takeoff_settings: {
                        ...state.outputData?.takeoff_settings,
                        ...payload
                    } 
                }
            }
        case 'SET_SHOW_TOOLTIP':
            return {
                ...state,
                outputData: {
                    ...state.outputData,
                    show_feature_preview: payload
                }
                
            };
        case 'UPDATE_ASSEMBLIES':
            // TAKES ASSEMBLY LIST AND FEATURE ID;
            // payload = {output_id, assemblies: []};
            output_id = payload?.output_id;
            outputs = state?.outputData?.outputs || [];
            filteredOutput = outputs.find((output: $TSFixMe) => output.id === output_id);
            if (filteredOutput) {
                filteredOutput.assemblies = payload?.assemblies || [];
                if (filteredOutput?.assemblies?.length) {
                    filteredOutput.feature.is_assembly_feature = true;
                } else {
                    filteredOutput.feature.is_assembly_feature = false;
                }
                const newOutput = [...outputs];
                return {
                    ...state,
                    outputData: {
                        ...state.outputData,
                        outputs: newOutput
                    }
                };
            }
            return {
                ...state
            };
        case 'RESET_OUTPUT':
            return { ...state, initialState };
        default:
            return state;
    }
};

export const useOutputData = create((set: $TSFixMe) => ({
    ...initialState,
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
}));
