import Draw from 'ol/interaction/Draw';
import { fromExtent } from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import { MODIFY_STYLE } from '../MapBase';
import { GEOMETRY_TYPE_STRING, LAYER_INDEX, MAP_LAYERS } from '../../../Constants/Constant';
import { changeMapCursor } from '../../../Utils/HelperFunctions';
import { Observer } from '../../../Utils/Observer';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';

// ScaleLine is an observable class i.e., it is able to emit events in realtime
class ScaleLine extends Observer {
    baseLayerProps: $TSFixMe;

    bpLotExtent: $TSFixMe;

    draw: $TSFixMe;

    invalidSpace: $TSFixMe;

    layer: $TSFixMe;

    mapObj: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.draw = null;
        this.layer = null;
        this.baseLayerProps = null;
        this.invalidSpace = false;
        this.bpLotExtent = null;
    }

    on() {
        // Return if base layer is not a blueprint sheet
        if (!this.mapObj.isBlueprintMap) {
            this.off();
            return;
        }

        this.mapObj.map.on('pointermove', this.highlightFeatureOnHover);

        // Blueprint sheet info
        this.baseLayerProps = this.mapObj.baseLayer?.getProperties() || {};
        const polygon = fromExtent(this.baseLayerProps.bp_page_extent);
        const feature = new Feature(polygon);
        this.bpLotExtent = feature;

        // Add a new temporary layer for drawing
        const src = new VectorSource({ wrapX: false });

        this.layer = new VectorLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; source: VectorSour... Remove this comment to see the full error message
            id: 'empty-draw-layer',
            source: src,
            name: MAP_LAYERS.OUTPUT,
            style: MODIFY_STYLE,
            zIndex: LAYER_INDEX.DRAW
        });

        this.mapObj.addLayer(this.layer);

        // Add draw interaction on the layer
        this.draw = new Draw({
            type: GEOMETRY_TYPE_STRING.LINESTRING,
            source: src,
            condition: e => {
                const mouseClick = e.originalEvent.button;
                if (mouseClick === 2 || mouseClick === 1 || this.invalidSpace) {
                    return false;
                }
                return true;
            },
            snapTolerance: 1,
            ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
        });

        this.draw.on('drawend', this.handleDrawEnd);
        this.mapObj.map.addInteraction(this.draw);

        document.addEventListener('keydown', this.removeLastPointOnBack);
    }

    // Listen to incoming events in realtime
    newEvent(eventType: $TSFixMe) {
        if (eventType === TOOL_EVENT.REMOVE_SCALE_LINE) {
            this.layer.getSource().clear();
        }
    }

    highlightFeatureOnHover = (e: $TSFixMe) => {
        this.invalidSpace = !this.mapObj.coordsExistsInParcel(e.coordinate, this.bpLotExtent);
        changeMapCursor(this.invalidSpace, 'not-allowed');
    };

    removeLastPointOnBack = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (KeyID === 8) {
            this.draw.removeLastPoint();
        }
        if (KeyID === 27) {
            this.draw.abortDrawing();
        }
    };

    handleDrawEnd = (e: $TSFixMe) => {
        let geojson = new GeoJSON().writeFeatures([e.feature]);
        geojson = geojson && JSON.parse(geojson);
        this.notifyObservers(TOOL_EVENT.SCALE_LINE_ADDED, geojson);
    };

    off() {
        if (this.draw) {
            this.mapObj.map.removeInteraction(this.draw);
            this.draw.un('drawend', this.handleDrawEnd);
        }
        this.baseLayerProps = null;
        if (this.layer) {
            this.mapObj.removeLayer(this.layer);
        }
        document.removeEventListener('keydown', this.removeLastPointOnBack);
        this.mapObj.map.un('pointermove', this.highlightFeatureOnHover);
    }
}

export default ScaleLine;
