const COOKIE_NAME = 'maxTryReload';
const MAX_TRY = 2;
const EXPIRES_MIN = 1;

// Reload window is max tries not reached
export default function chunkReloadHandler() {
    const cookie = getCookie(COOKIE_NAME);
    if (cookie) {
        const maxTry = parseInt(cookie, 10);
        // @ts-expect-error TS(2365): Operator '>' cannot be applied to types 'string' a... Remove this comment to see the full error message
        if (cookie > 0) {
            updateCookie(COOKIE_NAME, maxTry - 1, EXPIRES_MIN);
            window.location.reload();
        }
    } else {
        setCookie(COOKIE_NAME, MAX_TRY, EXPIRES_MIN);
        window.location.reload();
    }
}

function setCookie(cname: $TSFixMe, cvalue: $TSFixMe, minutes: $TSFixMe) {
    const d = new Date();
    d.setTime(d.getTime() + minutes * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

function updateCookie(cname: $TSFixMe, cvalue: $TSFixMe, minutes: $TSFixMe) {
    setCookie(cname, cvalue, minutes);
}

export function getCookie(cname: $TSFixMe) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
