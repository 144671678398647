import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { USER_OS } from '../Constants/Constant';

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
    switch (type) {
        case 'SET_BREAKPOINT_RESET':
            return {
                ...state,
                screen: {
                    breakpoint: 'lg',
                    isMobileDevice: false
                }
            };
        case 'SET_BREAKPOINT': {
            const { breakpoint = 'lg', isMobileDevice, mobileView } = payload || {};
            return {
                ...state,
                screen: {
                    breakpoint,
                    isMobileDevice,
                    mobileView
                }
            };
        }
        case 'SET_USER_OS':
            return { ...state, userOS: payload };
        default:
            return state;
    }
};

const store = (set: $TSFixMe) => ({
    screen: { breakpoint: 'lg', isMobileDevice: false, mobileView: false },
    userOS: USER_OS.UNKNOWN,
    dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useDevice = create(devtools(store));
